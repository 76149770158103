export default function Reading() {
    return [
        '',
        'T1: Getting started with HTML',
        'T2: Getting started with CSS',
        'T3: Designing a webpage layout',
        'T4: Graphic design with CSS',
        'T5: Designing for the mobile web',
        'T6: Tables and columns',
        'T7: Designing web forms',
        'T8: Enhancing with Multimedia',
        'T9: Javascript (optional)',
    ];
}
