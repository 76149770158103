import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid'; // Grid version 1
import Description from "./description.mdx"
import logo_dark from './logo_dark.png'
import logo_light from './logo_light.png'

export default function Index() {
    return (
        <Grid container spacing={2} columns={{ sm: 6, md: 12 }}>
            <Grid item xs={12}>
                <h1>CS 210 - Web Development I</h1>
            </Grid>
            <Grid item xs={12}>
                <div style={{ float: 'right', margin: '20px' }}>
                    <img src={logo_dark} className="only-dark-mode" alt="CS 210 Mock page." />
                    <img src={logo_light} className="only-light-mode" alt="CS 210 Mock page." />

                </div>

                Most of the material for the CS 210 class is the on the Moodle site. You must
                check the Moodle site frequently for this course.

                <em>
                Special thanks to Dr. Maggie Vanderberg for preparing the
                materials for this course. Almost all of the assignments are
                based on her work with minor adaptations.
                </em>

                <Description />

                <Alert severity="info">
                    If this is your first time here, use the menu on the left
                    to find the Syllabus and the Schedule. The schedule is the
                    most important page to check regularly.
                </Alert>

            </Grid>
        </Grid>
    )
}
