import { Link } from "react-router-dom";

export default function Due() {
    return [
        "",
        "",
        "",
        <Link to="/314/assignments/lab1">Lab 1</Link>,
        <Link to="/314/assignments/hw1">Homework 1</Link>,
        <Link to="/314/assignments/lab2">Lab 2</Link>,
        "",
        <div><Link to="/314/assignments/lab3">Lab 3</Link><br /><Link to="/314/assignments/hw2">Homework 2</Link></div>,
        <div><b>Quiz 1</b><br /><Link to="/314/assignments/hw3">Homework 3</Link></div>,
        <div><s>Lab 4</s> (nothing required)</div>,
        <b>Midterm</b>,
        "",
        <Link to="/314/assignments/hw4">Homework 4</Link>,
        "",
        "",
        <div><Link to="/314/assignments/hw5">Homework 5</Link></div>,
        "",
        "Lab 7",
        <div><b><Link to="/314/quiz2-review">Quiz 2</Link></b><br /><Link to="/314/assignments/hw6">Homework 6</Link></div>,
        <div><Link to="/314/assignments/buffer-overflow-lab">Buffer overflow lab</Link><br /><Link to="/314/assignments/hw7">Homework 7</Link></div>
    ];
}
