export default function Reading() {
    return [
        'Chs. 1-5 (40 pages)',               // 0
        'Chs. 6-7 (25 pages)',               // 1
        'Ch. 8 (25 pages)',
        'Chs. 9-11 (50 pages)',
        'Chs. 12-13 (30 pages)',
        'Chs. 14-16 (43 pages)',
        'Ch. 17 (30 pages)',
        'Ch. 18 (25 pages)',
        'Ch. 19 (22 pages)',
        <div>
            <a href="https://azeria-labs.com/writing-arm-assembly-part-1/">Azeria 1</a><br />
            <a href="https://azeria-labs.com/arm-data-types-and-registers-part-2/">Azeria 2</a><br />
            <a href="https://azeria-labs.com/arm-instruction-set-part-3/">Azeria 3</a><br />
            Ch. 20 (24 pages)
        </div>,
        '',
        'Ch. 21 (19 pages)',
        <div>
            <a href="https://azeria-labs.com/arm-conditional-execution-and-branching-part-6/">Azeria 4</a><br />
            Ch. 22 (20 pages)
        </div>,
        'Ch. 23 (24 pages)',
        <div>
            <a href="https://azeria-labs.com/arm-conditional-execution-and-branching-part-6/">Azeria 6</a><br />
            Ch. 24 (24 pages)
        </div>,
        <div>
            <a href="https://azeria-labs.com/load-and-store-multiple-part-5/">Azeria 5</a><br />
            <a href="https://azeria-labs.com/functions-and-the-stack-part-7/">Azeria 7</a><br />
            Ch. 25 (10 pages)
        </div>,
        'Ch. 26 (11 pages)',
        'Ch. 27 (18 pages)',
        <div>
            <a href="https://azeria-labs.com/stack-overflow-arm32/">Azeria Stack Overflows</a><br />
            <a href="https://azeria-labs.com/writing-arm-shellcode/">Azeria Shellcode</a><br />
        </div>,
        'Ch. 28 (12 pages)'
    ]
}

