import { Link } from "react-router-dom";

export default function Topics() {
    return [
        <a href="https://console.paperspace.com/tewqzz4fb/notebook/rqf3h1fdvzl9vfa">Course Overview, Python</a>,
        <a href="https://console.paperspace.com/tewqzz4fb/notebook/r8sicasasaulxc1">Tensors, FastAI, Training process</a>,
        <div>HuggingFace, Gradio, etc.<br />Project 1 (AI for the masses)</div>,
        <a href="https://console.paperspace.com/tewqzz4fb/notebook/rckn619mtcwbd0o">Neural Networks, MNIST, Stochastic Gradient Descent</a>,
        <a href="https://console.paperspace.com/tewqzz4fb/notebook/rmoblzbg2x5t8fu">Object detection</a>,
        <a href="https://colab.research.google.com/drive/1BI-RDWzEIqVqg0hPV_hj3OiZD8dThUIt?usp=sharing">Convolutional Networks and Lab 5 part 1</a>,
        <div>
            <a href="https://colab.research.google.com/drive/1S6i5g-Uffm2SqdFOq1C_-FYjZVCEeeOh#scrollTo=EBDFLuTZHtPQ">GANs</a><br />
            <a href="https://colab.research.google.com/drive/11uFo6Pn32tJlXOMKwhvvcwMMzUWtW3ro#scrollTo=b0P4xfdMJnty">FGSM</a><br />            
            <Link to="/356/assignments/project2">Project 2</Link><br />
        </div>,
        'OpenCV + YOLO',
        <div><a href="https://colab.research.google.com/drive/1-CnPamvyAr_LO6htb7GY8XysDoclnoe2?usp=sharing">Word Embeddings</a><br /><a href="https://1drv.ms/p/s!Aut_k8c38Nn4hIs86GIXM9WPoOjOvA?e=reamSv">Func2Vec</a><br /><a href="https://colab.research.google.com/drive/1EEIyq_YxF1mV7by8xZQzaRd8Z2ZbTSQz?usp=sharing">Lab 7</a></div>,
        <div>Serverless Chat Bots</div>,
        <div>Serverless Chat Bots</div>,
        <div>GPT-3<br />Project 3</div>,
        <div>Q-learning<br /></div>,
        <Link to="/356/assignments/deepracer">DeepRacer simulator</Link>,
        <div><a href="https://docs.google.com/presentation/d/15bLiOjhZDMS5X_A30bEBuGhtYGtCIOXAObOO52Lamx4/edit?usp=sharing">DQN Slides</a><br /><a href="https://colab.research.google.com/drive/1kFQEKIcrH02GD4mkIJJfkB97pHtUILID?usp=sharing">DQN CartPole</a></div>,
        <div><a href="https://shorturl.at/agik2">Board Game AI</a><br /><Link to="/356/assignments/alpha-zero-lab">Alpha Zero Lab</Link></div>,
        <div><a href="https://docs.google.com/presentation/d/19AJdonGGZ7A3-1jTyO7MqHath84jIxsZzMM17GLtdOQ/edit?usp=sharing">Stochastic Parrots</a><br />Student Topic Presentations</div>,
        <div>
            Student Topic Presentations 2<br />
            DeepRacer physical car
        </div>,
        <div>Work day<br /><Link to="/356/assignments/deepracer">DeepRacer</Link><br />Project 4</div>,
        <div>Work day<br /><Link to="/356/assignments/deepracer">DeepRacer</Link><br />Project 4</div>,
    ];
}
