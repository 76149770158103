import Box from "@mui/material/Box";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import uniqueId from 'lodash/uniqueId';


export default function Points() {

    type category = "tutorials" | "milestones" | "participation" | "quizzes";

    interface Assignment {
        name: string;
        category: category;
        points: Number;
    }


    const catPoints = (c: category, p: Number, t: category) => (
        c === t ? p : null
    );

    let assignments: Assignment[] = [
        {
            name: "Moodle: Introduction to web development"
            , category: "quizzes"
            , points: 2
        },
        {
            name: "Moodle: How this course works"
            , category: "quizzes"
            , points: 4
        },
        {
            name: "Quiz: Key aspects for success"
            , category: "quizzes"
            , points: 8
        },
        {
            name: "Forum Post: Introduce Yourself"
            , category: "participation"
            , points: 5
        },
        {
            name: "File Management Assignment"
            , category: "tutorials"
            , points:15 
        },
        {
            name: "Moodle: teaching.defreez.com"
            , category: "quizzes"
            , points:  2
        },
        {
            name: "Tutorial 1"
            , category: "tutorials"
            , points:  15
        },
        {
            name: "Tutorial 2"
            , category: "tutorials"
            , points:  15
        },
        {
            name: "Tutorial 3"
            , category: "tutorials"
            , points:  15
        },
        {
            name: "Tutorial 4"
            , category: "tutorials"
            , points:  15
        },
        {
            name: "Tutorial 5"
            , category: "tutorials"
            , points:  15
        },
        {
            name: "Tutorial 6"
            , category: "tutorials"
            , points:  15
        },
        {
            name: "Tutorial 7"
            , category: "tutorials"
            , points:  15
        },
        {
            name: "Tutorial 8"
            , category: "tutorials"
            , points:  15
        },
        {
            name: "Tutorial 9 (extra credit)"
            , category: "tutorials"
            , points:  15
        },
        {
            name: "Tutorial 1 Case"
            , category: "tutorials"
            , points:  5
        },
        {
            name: "Tutorial 2 Case"
            , category: "tutorials"
            , points:  5
        },
        {
            name: "Tutorial 3 Case"
            , category: "tutorials"
            , points:  5
        },
        {
            name: "Tutorial 4 Case"
            , category: "tutorials"
            , points:  5
        },
        {
            name: "Tutorial 5 Case"
            , category: "tutorials"
            , points:  5
        },
        {
            name: "Tutorial 7 Case"
            , category: "tutorials"
            , points:  5
        },
        {
            name: "Tutorial 8 Case"
            , category: "tutorials"
            , points:  5
        },
        {
            name: "Quiz 1"
            , category: "quizzes"
            , points:  10
        },
        {
            name: "Quiz 2"
            , category: "quizzes"
            , points:  10
        },
        {
            name: "Quiz 3"
            , category: "quizzes"
            , points:  12
        },
        {
            name: "Quiz 4"
            , category: "quizzes"
            , points:  12
        },
        {
            name: "Quiz 5"
            , category: "quizzes"
            , points:  10
        },
        {
            name: "Quiz 6"
            , category: "quizzes"
            , points:  10
        },
        {
            name: "Quiz 7"
            , category: "quizzes"
            , points:  10
        },
        {
            name: "Quiz 8"
            , category: "quizzes"
            , points:  10
        },
        {
            name: "Milestone 1"
            , category: "milestones"
            , points:  25
        },
        {
            name: "Milestone 2"
            , category: "milestones"
            , points:  25
        },
        {
            name: "Milestone 3"
            , category: "milestones"
            , points:  25
        },
        {
            name: "Milestone 4"
            , category: "milestones"
            , points:  25
        },
        {
            name: "Milestone 5"
            , category: "milestones"
            , points:  25
        },
        {
            name: "Milestone 1 Peer Review"
            , category: "participation"
            , points:  25
        },
        {
            name: "Milestone 2 Peer Review"
            , category: "participation"
            , points:  25
        },
        {
            name: "Milestone 3 Peer Review"
            , category: "participation"
            , points:  25
        },
        {
            name: "Milestone 4 Peer Review"
            , category: "participation"
            , points:  25
        },
        {
            name: "Milestone 5 Peer Review"
            , category: "participation"
            , points:  25
        },
        {
            name: "Course Reflection Survey"
            , category: "participation"
            , points:  25
        },
        {
            name: "Final Project Website"
            , category: "milestones"
            , points:  200
        },
    ];

    let expandedAssignments: GridRowsProp = assignments.map((a) => {
        return {
            id: uniqueId(),
            name: a.name,
            tutorialsPoints: catPoints(a.category, a.points, "tutorials"),
            milestonesPoints: catPoints(a.category, a.points, "milestones"),
            participationPoints: catPoints(a.category, a.points, "participation"),
            quizzesPoints: catPoints(a.category, a.points, "quizzes")
        }
    });

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Assignment', flex: 3 },
        { field: 'tutorialsPoints', headerName: 'Tutorials', flex: 1 },
        { field: 'milestonesPoints', headerName: 'Term Project', flex: 1 },
        { field: 'participationPoints', headerName: 'Participation', flex: 1 },
        { field: 'quizzesPoints', headerName: 'Quizzes', flex: 1 },
    ];

    return (
            <Box sx={{ flexGrow: 1, m: 2 }}>
                <h1>CS 210 Points Breakdown</h1>
                <p>These are the point values that will be used for all assignments.</p>
                <ul>
                    <li>The milestones are important, and the final project is the most important assignment.</li>
                    <li>It is possible to get an A without doing any of the case assignments. It is easier to get an A if you do some of them.</li>
                </ul>
                <DataGrid rows={expandedAssignments} columns={columns} autoHeight sx={{mt: 6}}/>
            </Box>
    );
}
