// The main content area can be one of two things - <Notes> or <SlideShow>
// A <SlideShow> must consit of <Slide> children.

import React, { useState, useEffect, useCallback } from 'react';
import { Children } from 'react';
import Box from '@mui/material/Box';
import { useParentContext } from "../app";
import { Layouts } from "./slide";

import IconButton from "@mui/material/IconButton";
import SlideshowIcon from '@mui/icons-material/Slideshow';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';


interface SlideshowProps {
    children: React.ReactNode
}

// Slideshow needs to map over Children in order to use convenient
// nesting in MDX files. Because I am using MDX for the slideshow
// it doesn't seem like any of the alternatives to Children
// listed in the beta React docs are a good fit. We wil keep the use
// of Children to a minimum here - only used to hide and show slides.

// A Slideshow must consist of only Slide children.
// Nested slides are not supported.
// Custom components that generate Slide components are not supported.
export default function Slideshow(props: SlideshowProps) {
    let children = props.children;
    let total_slides = Children.count(children);

    const parentContext = useParentContext();

    // Add the slideshow button to the nav bar.
    const [, setExtraButtons] = parentContext.extraButtonsContext;
    const [isSlideShow, setIsSlideShow] = parentContext.slideShowContext;
    const [slideCursor, setSlideCursor] = useState(1);
    useEffect(() => {
        const toggleSlideShow = () => {
            setIsSlideShow(!isSlideShow);
        };
        setExtraButtons([
            <IconButton
                sx={{ ml: 1 }}
                color="inherit"
                key="play-slideshow"
                onClick={toggleSlideShow}
            >
                {isSlideShow ? (
                    <CancelPresentationIcon />
                ) : (
                    <SlideshowIcon />
                )}
            </IconButton>
        ]);
    }, [isSlideShow, setIsSlideShow, setExtraButtons]);



    const prevSlide = useCallback(() => {
        if (slideCursor > 1) {
            setSlideCursor(slideCursor - 1);
        }
    }, [slideCursor, setSlideCursor]);

    const nextSlide = useCallback(() => {
        if (slideCursor < total_slides) {
            setSlideCursor(slideCursor + 1);
        }
    }, [slideCursor, setSlideCursor, total_slides]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "ArrowRight") {
                nextSlide();
            } else if (event.key === "ArrowLeft") {
                prevSlide();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [nextSlide, prevSlide]);

    if (!isSlideShow) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }

    const slides = Children.map(children, (child, idx) => {
        if (idx === slideCursor - 1) {
            if (!React.isValidElement(child) || !child.props) {
                return child;
            }
            if (!('layout' in child.props)) {
                return child;
            }

            const content = (c: typeof child) => {
                if (c.props.layout === Layouts.Title) {
                    return (<Box sx={{
                        gridRow: '4 / 11',
                        gridColumn: '1/12',
                        textAlign: 'center',
                        height: '100%',
                        fontSize: '3em',
                    }}>
                        {c}
                    </Box>);
                } else if (c.props.layout === Layouts.Section) {
                    return (<Box sx={{
                        gridRow: '5 / 11',
                        gridColumn: '1/12',
                        height: '100%',
                        fontSize: '2.5em',
                    }}>
                        {c}
                    </Box>);
                } else if (c.props.layout === Layouts.TitleAndBody) {
                    return (<Box sx={{
                        gridRow: '1 / 11',
                        gridColumn: '1/12',
                        height: '100%',
                        fontSize: '2em',
                    }}>
                        {c}
                    </Box>);
                } else if (c.props.layout === Layouts.TitleAndTwoColumns) {
                    return (
                        <React.Fragment>
                            <Box sx={{
                                gridRow: '1 / 1',
                                gridColumn: '1/12',
                                height: '100%',
                                fontSize: '2em',
                            }}>
                                {c.props.children[0]}
                            </Box>
                            <Box sx={{
                                gridRow: ' 2 / 12',
                                gridColumn: '1/6',
                                height: '100%',
                                fontSize: '2em',
                            }}>
                                {c.props.children[1]}
                            </Box>
                            <Box sx={{
                                gridRow: ' 2 / 12',
                                gridColumn: '7/13',
                                height: '100%',
                                fontSize: '2em',
                            }}>
                                {c.props.children[2]}
                            </Box>
                        </React.Fragment>
                    );
                } else {
                    return c;
                }
            };

            return (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    ml: 4,
                }}>
                    <Box sx={{
                        display: 'grid',
                        width: '100%',
                        gridTemplateColumns: 'repeat(12, 1fr)',
                        gridTemplateRows: 'repeat(12, 1fr)',
                        height: '100%',
                        m: 'auto'
                    }}
                    >
                        {content(child)}
                        <Box sx={{ gridRow: '12', gridColumn: '12/12', mr: 2 }}>
                            <ButtonGroup variant="outlined" aria-label="outlined button group">
                                <Button onClick={prevSlide}>
                                    <ArrowBackIosIcon />
                                </Button>
                                <Button onClick={nextSlide}>
                                    <ArrowForwardIosIcon />
                                </Button>
                            </ButtonGroup>
                            <br />
                            <Typography align="center">{idx + 1} / {total_slides}</Typography>
                        </Box>
                    </Box>
                </Box>
            );
        }
    });

    return (
        <React.Fragment>
            {slides}
        </React.Fragment>
    );
}
