import * as React from "react";
import ReactDOM from "react-dom/client";
import { StyledEngineProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./app";

import Home from "./home.mdx";
import Notes from "./routes/notes";

import Cs210 from "./routes/cs210/index";
import Cs210Syllabus from "./routes/cs210/syllabus.mdx";
import Cs210Schedule from "./routes/cs210/schedule.mdx";
import Cs210Points from "./routes/cs210/points";
import Cs210Milestone1 from "./routes/cs210/assignments/milestone1.mdx";
import Cs210Milestone2 from "./routes/cs210/assignments/milestone2.mdx";
import Cs210Milestone3 from "./routes/cs210/assignments/milestone3.mdx";
import Cs210Milestone4 from "./routes/cs210/assignments/milestone4.mdx";
import Cs210Milestone5 from "./routes/cs210/assignments/milestone5.mdx";
import Cs210Final from "./routes/cs210/assignments/final-project.mdx";
import Cs210Inspect from "./routes/cs210/tips/inspect.mdx";

import Cs314 from "./routes/cs314/index.mdx";
import Cs314Syllabus from "./routes/cs314/syllabus.mdx";
import Cs314Arm from "./routes/cs314/topics/arm/index.mdx";
import Cs314ArmFactorial from "./routes/cs314/topics/arm/factorial.mdx";
import Cs314Hdl from "./routes/cs314/topics/hdl/index.mdx";
import Cs314Schedule from "./routes/cs314/schedule.mdx";
import Cs314Resources from "./routes/cs314/resources.mdx";
import Cs314Intro from "./routes/cs314/topics/intro.mdx";
import Cs314Algebra from "./routes/cs314/topics/boolean-algebra.mdx";
import Cs314Lab1 from "./routes/cs314/assignments/lab1.mdx";
import Cs314Hw1 from "./routes/cs314/assignments/hw1.mdx";
import Cs314Lab2 from "./routes/cs314/assignments/hw2/lab2.mdx";
import Cs314Hw2 from "./routes/cs314/assignments/hw2/hw2.mdx";
import Cs314AddSub from "./routes/cs314/topics/addition-subtraction.mdx";
import Cs314Hw3 from "./routes/cs314/assignments/hw3/hw3.mdx";
import Cs314Lab3 from "./routes/cs314/assignments/hw3/lab3.mdx";
import Cs314Lab4 from "./routes/cs314/assignments/lab4.mdx";
import Cs314Lab5 from "./routes/cs314/assignments/lab5.mdx";
import Cs314BufferOverflowLab from "./routes/cs314/assignments/buffer-overflow-lab.mdx";
import Cs314Hw4 from "./routes/cs314/assignments/hw4.mdx";
import Cs314Hw5 from "./routes/cs314/assignments/hw5/hw5.mdx";
import Cs314Hw6 from "./routes/cs314/assignments/hw6/hw6.mdx";
import Cs314Hw7 from "./routes/cs314/assignments/hw7/hw7.mdx";
import Cs314ArmIntro from "./routes/cs314/topics/arm/intro.mdx";
import Cs314Arm2 from "./routes/cs314/lessons/arm-programming-2.mdx";
import Cs314Arm3 from "./routes/cs314/lessons/arm-programming-3.mdx";
import Cs314Quiz2Review from "./routes/cs314/quiz2-review.mdx";

import Cs210Assignments from "./routes/cs210/assignments/index.mdx";
import Cs210FileManagement from "./routes/cs210/assignments/file-management/file-management.mdx";

import Cs356 from "./routes/cs356/index.mdx";
import Cs356Syllabus from "./routes/cs356/syllabus.mdx";
import Cs356Schedule from "./routes/cs356/schedule.mdx";
import Cs356Assignments from "./routes/cs356/assignments/index.mdx";
import Cs356Lab0 from "./routes/cs356/assignments/lab0.mdx";
import Cs356Lab1 from "./routes/cs356/assignments/lab1.mdx";
import Cs356Lab2 from "./routes/cs356/assignments/lab2.mdx";
import Cs356AlphaZeroLab from "./routes/cs356/assignments/alpha-zero-lab.mdx";
import Cs356Project1 from "./routes/cs356/assignments/project1.mdx";
import Cs356Project2 from "./routes/cs356/assignments/project2.mdx";
import Cs356Project3 from "./routes/cs356/assignments/project3/chat-bot-lab.mdx";
import Cs356Project4 from "./routes/cs356/assignments/project4.mdx";
import Cs356CodedBias from "./routes/cs356/assignments/coded-bias-review.mdx";
import Cs356AlphaGo from "./routes/cs356/assignments/alpha-go-review.mdx";
import Cs356Deepracer from "./routes/cs356/assignments/deepracer.mdx";

import Cs418 from "./routes/cs418/index";
import Cs418Schedule from "./routes/cs418/schedule.mdx";
import Cs418Syllabus from "./routes/cs418/syllabus.mdx";
import Cs418Prelims from "./routes/cs418/slides/0-prelims/0-prelims.mdx";
import Cs418Dfas from "./routes/cs418/slides/1-dfas/1-dfas.mdx";
import Cs418Nfas from "./routes/cs418/slides/2-nfas/nfas.mdx";
import Cs418Regex from "./routes/cs418/slides/3-regex/regex.mdx";
import Cs418DfaMinimization from "./routes/cs418/slides/4-notreg/minimization.mdx";
import Cs418MyhillNerode from "./routes/cs418/slides/4-notreg/myhill-nerode.mdx";
import Cs418PumpingLemma from "./routes/cs418/slides/4-notreg/pumping-lemma.mdx";
import Cs418Cfg from "./routes/cs418/slides/5-cfg/cfg.mdx";
import Cs418Pda from "./routes/cs418/slides/6-pda/pda.mdx";
import Cs418Cyk from "./routes/cs418/slides/7-cyk/cyk.mdx";
import Cs418Lambda from "./routes/cs418/slides/8-lambda/lambda.mdx";
import Cs418TuringMachines from "./routes/cs418/slides/9-turing-machines/turing.mdx";
import Cs418Diagonalization from "./routes/cs418/slides/10-diagonalization/diagonalization.mdx";
import Cs418HaltingProblem from "./routes/cs418/slides/11-halting-problem/halting-problem.mdx";
import Cs418RicesTheorem from "./routes/cs418/slides/12-rices-theorem/rices-theorem.mdx";
import Cs418PvsNP from "./routes/cs418/slides/13-p-vs-np/p-vs-np.mdx";
import Cs418NpComplete from "./routes/cs418/slides/14-np-complete/np-complete.mdx";
import Cs418Hw0 from "./routes/cs418/hw/hw0.mdx";
import Cs418Hw1a from "./routes/cs418/hw/hw1a.mdx";
import Cs418Hw1b from "./routes/cs418/hw/hw1b.mdx";
import Cs418Hw1c from "./routes/cs418/hw/hw1c.mdx";
import Cs418Hw1d from "./routes/cs418/hw/hw1d.mdx";
import Cs418Hw2a from "./routes/cs418/hw/hw2a.mdx";
import Cs418Hw2b from "./routes/cs418/hw/hw2b.mdx";
import Cs418Hw2c from "./routes/cs418/hw/hw2c.mdx";
import Cs418Hw3a from "./routes/cs418/hw/hw3a.mdx";
import Cs418Hw4a from "./routes/cs418/hw/hw4a.mdx";
import Cs418Hw4b from "./routes/cs418/hw/hw4b.mdx";
import Cs418Hw5a from "./routes/cs418/hw/hw5a.mdx";
import Cs418Hw7a from "./routes/cs418/hw/hw7a.mdx";
import Cs418Resources from "./routes/cs418/resources.mdx";
import Cs418OldExams from "./routes/cs418/old-exams.mdx";

import Cs257 from "./routes/cs257/index";
import Cs257Schedule from "./routes/cs257/schedule.mdx";
import Cs257Syllabus from "./routes/cs257/syllabus.mdx";
import Cs257Lab0 from "./routes/cs257/labs/lab0/lab0.mdx";
import Cs257Lab1 from "./routes/cs257/labs/lab1/lab1.mdx";
import Cs257Lab2 from "./routes/cs257/labs/lab2/lab2.mdx";
import Cs257Lab3 from "./routes/cs257/labs/lab3/lab3.mdx";
import Cs257Lab4 from "./routes/cs257/labs/lab4/lab4.mdx";
import Cs257Lab5 from "./routes/cs257/labs/lab5/lab5.mdx";
import Cs257Lab6 from "./routes/cs257/labs/lab6/lab6.mdx";
import Cs257ProjectA from "./routes/cs257/projects/a/wordle.mdx";
import Cs257ProjectB from "./routes/cs257/projects/b/tft.mdx";
import Cs257ProjectC from "./routes/cs257/projects/c/c.mdx";
import Cs257Final from "./routes/cs257/projects/final/final.mdx";
import Cs257Resources from "./routes/cs257/resources.mdx";
import Cs257LinuxCli1 from "./routes/cs257/slides/linux/cli1.mdx";
import Cs257Day2 from "./routes/cs257/slides/day2.mdx";
import Cs257Debugging from "./routes/cs257/slides/3-debugging/debugging.mdx";
import Cs257DataTypes from "./routes/cs257/slides/4-fundamental-types/types.mdx";
import Cs257ControlFlow from "./routes/cs257/slides/7-control-flow/control-flow.mdx";
import Cs257Bitset from "./routes/cs257/topics/bitset.mdx";
import Cs257Refs from "./routes/cs257/slides/8-refs-and-pointers/pointers.mdx";
import Cs257Oop2 from "./routes/cs257/slides/11-oop2/oop2.mdx";


// Everything pre-slideshow is wrapped in <Notes> here because
// it was the easiest place to do search/replace.
ReactDOM.createRoot(document.querySelector("#root")!).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="210" element={<Notes><Cs210 /></Notes>} />
            <Route path="210/syllabus" element={<Notes><Cs210Syllabus /></Notes>} />
            <Route path="210/schedule" element={<Notes><Cs210Schedule /></Notes>} />
            <Route path="210/points" element={<Notes><Cs210Points /></Notes>} />
            <Route path="210/assignments" element={<Notes><Cs210Assignments /></Notes>} />
            <Route path="210/assignments/file-management" element={<Notes><Cs210FileManagement /></Notes>} />
            <Route path="210/assignments/milestone1" element={<Notes><Cs210Milestone1 /></Notes>} />
            <Route path="210/assignments/milestone2" element={<Notes><Cs210Milestone2 /></Notes>} />
            <Route path="210/assignments/milestone3" element={<Notes><Cs210Milestone3 /></Notes>} />
            <Route path="210/assignments/milestone4" element={<Notes><Cs210Milestone4 /></Notes>} />
            <Route path="210/assignments/milestone5" element={<Notes><Cs210Milestone5 /></Notes>} />
            <Route path="210/assignments/final-project" element={<Notes><Cs210Final /></Notes>} />
            <Route path="210/tips/inspect" element={<Notes><Cs210Inspect /></Notes>} />
            <Route path="314" element={<Notes><Cs314 /></Notes>} />
            <Route path="314/syllabus" element={<Notes><Cs314Syllabus /></Notes>} />
            <Route path="314/schedule" element={<Notes><Cs314Schedule /></Notes>} />
            <Route path="314/resources" element={<Notes><Cs314Resources /></Notes>} />
            <Route path="314/topics" element={<Notes><Cs314Schedule /></Notes>} />
            <Route path="314/topics/intro" element={<Notes><Cs314Intro /></Notes>} />
            <Route path="314/topics/boolean-algebra" element={<Notes><Cs314Algebra /></Notes>} />
            <Route path="314/topics/addition-subtraction" element={<Notes><Cs314AddSub /></Notes>} />
            <Route path="314/assignments" element={<Notes><Cs314Schedule /></Notes>} />
            <Route path="314/assignments/lab1" element={<Notes><Cs314Lab1 /></Notes>} />
            <Route path="314/assignments/hw1" element={<Notes><Cs314Hw1 /></Notes>} />
            <Route path="314/assignments/lab2" element={<Notes><Cs314Lab2 /></Notes>} />
            <Route path="314/assignments/hw2" element={<Notes><Cs314Hw2 /></Notes>} />
            <Route path="314/assignments/hw3" element={<Notes><Cs314Hw3 /></Notes>} />
            <Route path="314/assignments/lab3" element={<Notes><Cs314Lab3 /></Notes>} />
            <Route path="314/assignments/lab4" element={<Notes><Cs314Lab4 /></Notes>} />
            <Route path="314/assignments/lab5" element={<Notes><Cs314Lab5 /></Notes>} />
            <Route path="314/assignments/buffer-overflow-lab" element={<Notes><Cs314BufferOverflowLab /></Notes>} />
            <Route path="314/assignments/hw4" element={<Notes><Cs314Hw4 /></Notes>} />
            <Route path="314/assignments/hw5" element={<Notes><Cs314Hw5 /></Notes>} />
            <Route path="314/assignments/hw6" element={<Notes><Cs314Hw6 /></Notes>} />
            <Route path="314/assignments/hw7" element={<Notes><Cs314Hw7 /></Notes>} />
            <Route path="314/topics/arm/intro" element={<Notes><Cs314ArmIntro /></Notes>} />
            <Route path="314/quiz2-review" element={<Notes><Cs314Quiz2Review /></Notes>} />
            <Route path="314/topics/hdl" element={<Notes><Cs314Hdl /></Notes>} />
            <Route path="314/topics/arm" element={<Notes><Cs314Arm /></Notes>} />
            <Route path="314/lessons/arm-programming-2" element={<Notes><Cs314Arm2 /></Notes>} />
            <Route path="314/lessons/arm-programming-3" element={<Notes><Cs314Arm3 /></Notes>} />
            <Route path="314/topics/arm/factorial" element={<Notes><Cs314ArmFactorial /></Notes>} />

            <Route path="356" element={<Notes><Cs356 /></Notes>} />
            <Route path="356/syllabus" element={<Notes><Cs356Syllabus /></Notes>} />
            <Route path="356/schedule" element={<Notes><Cs356Schedule /></Notes>} />
            <Route path="356/assignments" element={<Notes><Cs356Assignments /></Notes>} />
            <Route path="356/assignments/lab0" element={<Notes><Cs356Lab0 /></Notes>} />
            <Route path="356/assignments/lab1" element={<Notes><Cs356Lab1 /></Notes>} />
            <Route path="356/assignments/lab2" element={<Notes><Cs356Lab2 /></Notes>} />
            <Route path="356/assignments/project1" element={<Notes><Cs356Project1 /></Notes>} />
            <Route path="356/assignments/project2" element={<Notes><Cs356Project2 /></Notes>} />
            <Route path="356/assignments/project3" element={<Notes><Cs356Project3 /></Notes>} />
            <Route path="356/assignments/project4" element={<Notes><Cs356Project4 /></Notes>} />
            <Route path="356/assignments/coded-bias-review" element={<Notes><Cs356CodedBias /></Notes>} />
            <Route path="356/assignments/alphago-review" element={<Notes><Cs356AlphaGo /></Notes>} />
            <Route path="356/assignments/alpha-zero-lab" element={<Notes><Cs356AlphaZeroLab /></Notes>} />
            <Route path="356/assignments/deepracer" element={<Notes><Cs356Deepracer /></Notes>} />

            <Route path="418" element={<Notes><Cs418 /></Notes>} />
            <Route path="418/schedule" element={<Notes><Cs418Schedule /></Notes>} />
            <Route path="418/syllabus" element={<Notes><Cs418Syllabus /></Notes>} />
            <Route path="418/slides/0-prelims" element={<Cs418Prelims />} />
            <Route path="418/slides/1-dfas" element={<Cs418Dfas />} />
            <Route path="418/slides/2-nfas" element={<Cs418Nfas />} />
            <Route path="418/slides/3-regex" element={<Cs418Regex />} />
            <Route path="418/slides/4-notreg/minimization" element={<Cs418DfaMinimization/>} />
            <Route path="418/slides/4-notreg/myhill-nerode" element={<Cs418MyhillNerode />} />
            <Route path="418/slides/4-notreg/pumping-lemma" element={<Cs418PumpingLemma />} />
            <Route path="418/slides/5-cfg" element={<Cs418Cfg/>} />
            <Route path="418/slides/6-pda" element={<Cs418Pda/>} />
            <Route path="418/slides/7-cyk" element={<Cs418Cyk/>} />
            <Route path="418/slides/8-lambda" element={<Cs418Lambda />} />
            <Route path="418/slides/9-turing-machines" element={<Cs418TuringMachines />} />
            <Route path="418/slides/10-diagonalization" element={<Cs418Diagonalization />} />
            <Route path="418/slides/11-halting-problem" element={<Cs418HaltingProblem />} />
            <Route path="418/slides/12-rices-theorem" element={<Cs418RicesTheorem />} />
            <Route path="418/slides/13-p-vs-np" element={<Cs418PvsNP/>} />
            <Route path="418/slides/14-np-complete" element={<Cs418NpComplete/>} />
            <Route path="418/hw0" element={<Cs418Hw0 />} />
            <Route path="418/hw1a" element={<Cs418Hw1a />} />
            <Route path="418/hw1b" element={<Cs418Hw1b />} />
            <Route path="418/hw1c" element={<Cs418Hw1c />} />
            <Route path="418/hw1d" element={<Cs418Hw1d />} />
            <Route path="418/hw2a" element={<Cs418Hw2a />} />
            <Route path="418/hw2b" element={<Cs418Hw2b />} />
            <Route path="418/hw2c" element={<Cs418Hw2c />} />
            <Route path="418/hw3a" element={<Cs418Hw3a />} />
            <Route path="418/hw4a" element={<Cs418Hw4a />} />
            <Route path="418/hw4b" element={<Cs418Hw4b />} />
            <Route path="418/hw5a" element={<Cs418Hw5a />} />
            <Route path="418/hw7a" element={<Cs418Hw7a />} />
            <Route path="418/resources" element={<Notes><Cs418Resources /></Notes>} />
            <Route path="418/old-exams" element={<Notes><Cs418OldExams /></Notes>} />

            <Route path="257" element={<Notes><Cs257 /></Notes>} />
            <Route path="257/schedule" element={<Notes><Cs257Schedule /></Notes>} />
            <Route path="257/syllabus" element={<Notes><Cs257Syllabus /></Notes>} />
            <Route path="257/resources" element={<Notes><Cs257Resources /></Notes>} />
            <Route path="257/lab0" element={<Notes><Cs257Lab0 /></Notes>} />
            <Route path="257/lab1" element={<Notes><Cs257Lab1 /></Notes>} />
            <Route path="257/lab2" element={<Notes><Cs257Lab2 /></Notes>} />
            <Route path="257/lab3" element={<Notes><Cs257Lab3 /></Notes>} />
            <Route path="257/lab4" element={<Notes><Cs257Lab4 /></Notes>} />
            <Route path="257/lab5" element={<Notes><Cs257Lab5 /></Notes>} />
            <Route path="257/lab6" element={<Notes><Cs257Lab6 /></Notes>} />
            <Route path="257/projects/a" element={<Notes><Cs257ProjectA /></Notes>} />
            <Route path="257/projects/b" element={<Notes><Cs257ProjectB /></Notes>} />
            <Route path="257/projects/c" element={<Notes><Cs257ProjectC /></Notes>} />
            <Route path="257/final-hurdle6137" element={<Notes><Cs257Final /></Notes>} />
            <Route path="257/slides/linux/cli1" element={<Cs257LinuxCli1 />} />
            <Route path="257/slides/day2" element={<Cs257Day2 />} />
            <Route path="257/slides/debugging" element={<Cs257Debugging />} />
            <Route path="257/slides/data-types" element={<Cs257DataTypes/>} />
            <Route path="257/slides/control-flow" element={<Cs257ControlFlow />} />
            <Route path="257/topics/bitset" element={<Notes><Cs257Bitset /></Notes>} />
            <Route path="257/slides/refs-and-pointers" element={<Cs257Refs />} />
            <Route path="257/slides/oop2" element={<Cs257Oop2 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StyledEngineProvider>
  </React.StrictMode>
);
