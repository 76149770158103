import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from '@mui/material/Paper';

import uniqueId from 'lodash/uniqueId';

interface TruthTableContent {
    header: string[];
    rows : Number[][];
}

export default function TruthTable(props: TruthTableContent) {
    return (
        <TableContainer component={Paper} sx={{ maxWidth: 200, margin: "auto" }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                    { props.header.map((x) => <TableCell key={uniqueId()}>{x}</TableCell>) }
                    </TableRow>
                </TableHead>
                <TableBody>
                    { props.rows.map((r) => 
                        <TableRow key={uniqueId()}>{
                            r.map((x) => <TableCell key={uniqueId()}>{x.toString()}</TableCell>)
                        }</TableRow> 
                    )}
                </TableBody>
            </Table>
        </TableContainer>

    );
}
