export default function Reading() {
    return [
        '',
        'Ch. 1',
        'Ch. 2',
        'Ch. 4 through SGD',
        <div>
            Finish Ch.4<br />
            Ch. 5 on cross-entropy loss<br />
        </div>,
        'Chapter 13',
        <a href="https://pytorch.org/tutorials/beginner/dcgan_faces_tutorial.html">PyTorch DCGAN tutorial</a>,
        '',
        <div>Ch. 10<br /><a href="https://pytorch.org/tutorials/beginner/nlp/word_embeddings_tutorial.html">PyTorch Word Embeddings</a></div>,
        <div>Finish Ch. 10<br />Chapter 12 up to LSTMS</div>,
        <a href="https://huggingface.co/course/">HuggingFace Transformers course</a>,
        <a href="https://beta.openai.com/docs/introduction">OpenAI Docs</a>,
        <div>
            <a href="https://classroom.github.com/a/opSdCSaP">RL Gym Codespace</a><br />
            <a href="https://colab.research.google.com/drive/1sXRK2pORpaNCosKfm9VubMRQV604zaPc?usp=sharing">v0: Frozen Lake</a><br />
            <a href="https://colab.research.google.com/drive/1mvEqjTHiPVWxpyOHweDsTkjxQbN8IjTA?usp=sharing">v1: Frozen Lake with discount</a><br />
            <a href="https://colab.research.google.com/drive/1b21UEyBOG84FluxFVgEgHE-qgfLMYRQm?usp=sharing">v2: Frozen Lake TD</a><br />
        </div>,
        '',
        '',
        'AlphaGo Movie',
        'Ch. 3',
        '',
        'DeepRacer docs',
    ];
}
