import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import React, { useCallback } from "react";

export default function Nav() {

    const navigate = useNavigate();
    const handleOnClick = useCallback(
        (event: React.SyntheticEvent, nodeIds: string) => {
            if (!nodeIds.startsWith("X")) {
                return navigate(nodeIds, { replace: true });
            } else {
                return function() {};
            }
        },
        [navigate]
    );

    return (
        <TreeView
            aria-label="classes"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
                height: 240,
                flexGrow: 1,
                maxWidth: 40025,
                overflowY: "auto",
                m: 2,
                '& .MuiTreeItem-label': { fontSize: '1.25rem !important', },
            }}
            onNodeSelect={handleOnClick}
        >
            <TreeItem nodeId="/" label="Home" />
            <TreeItem nodeId="210" label="CS 210">
                <TreeItem nodeId="210/schedule" label="Schedule" />
                <TreeItem nodeId="210/syllabus" label="Syllabus" />
                <TreeItem nodeId="210/points" label="Points" />
                <TreeItem nodeId="X210/assignments" label="Assignments" >
                    <TreeItem nodeId="210/assignments/file-management" label="File Management" />
                    <TreeItem nodeId="210/assignments/milestone1" label="Milestone 1" />
                    <TreeItem nodeId="210/assignments/milestone2" label="Milestone 2" />
                    <TreeItem nodeId="210/assignments/milestone3" label="Milestone 3" />
                    <TreeItem nodeId="210/assignments/milestone4" label="Milestone 4" />
                    <TreeItem nodeId="210/assignments/milestone5" label="Milestone 5" />
                    <TreeItem nodeId="210/assignments/final-project" label="Final Project" />
                </TreeItem>
                <TreeItem nodeId="X210/tips" label="Tips" >
                    <TreeItem nodeId="210/tips/inspect" label="Inspecting Pages" />
                </TreeItem>
            </TreeItem>
            <TreeItem nodeId="257" label="CS 257">
                <TreeItem nodeId="257/schedule" label="Schedule" />
                <TreeItem nodeId="257/syllabus" label="Syllabus" />
                <TreeItem nodeId="X257/Assignments" label="Assignments" >
                    <TreeItem nodeId="257/lab0" label="Lab 0" />
                    <TreeItem nodeId="257/lab1" label="Lab 1" />
                    <TreeItem nodeId="257/lab2" label="Lab 2" />
                    <TreeItem nodeId="257/lab3" label="Lab 3" />
                    <TreeItem nodeId="257/lab4" label="Lab 4" />
                    <TreeItem nodeId="257/projects/a" label="Project A" />
                    <TreeItem nodeId="257/projects/b" label="Project B" />
                    <TreeItem nodeId="257/projects/c" label="Project C" />
                    <TreeItem nodeId="257/lab5" label="Lab 5" />
                    <TreeItem nodeId="257/lab6" label="Lab 6" />
                    <TreeItem nodeId="257/final-hurdle6137" label="Final" />
                </TreeItem>
                <TreeItem nodeId="257/resources" label="External Resources" />
            </TreeItem>
            <TreeItem nodeId="314" label="CS 314">
                <TreeItem nodeId="314/syllabus" label="Syllabus" />
                <TreeItem nodeId="314/schedule" label="Schedule" />
                <TreeItem nodeId="314/resources" label="Resources" />
                <TreeItem nodeId="314/assignments" label="Assignments" >
                    <TreeItem nodeId="314/assignments/lab1" label="Lab 1" />
                    <TreeItem nodeId="314/assignments/hw1" label="Homework 1" />
                    <TreeItem nodeId="314/assignments/lab2" label="Lab 2" />
                    <TreeItem nodeId="314/assignments/hw2" label="Homework 2" />
                    <TreeItem nodeId="314/assignments/lab3" label="Lab 3" />
                    <TreeItem nodeId="314/assignments/hw3" label="Homework 3" />
                    <TreeItem nodeId="314/assignments/lab4" label="Lab 4" />
                    <TreeItem nodeId="314/assignments/hw4" label="Homework 4" />
                    <TreeItem nodeId="314/assignments/lab5" label="Lab 5" />
                    <TreeItem nodeId="314/assignments/hw5" label="Homework 5" />
                    <TreeItem nodeId="314/assignments/hw6" label="Homework 6" />
                    <TreeItem nodeId="314/assignments/hw7" label="Homework 7" />
                    <TreeItem nodeId="314/assignments/buffer-overflow-lab" label="Buffer Overflow Lab" />
                </TreeItem>
                <TreeItem nodeId="314/quiz2-review" label="Quiz 2 Review" />
            </TreeItem>
            <TreeItem nodeId="356" label="CS 356">
                <TreeItem nodeId="356/syllabus" label="Syllabus" />
                <TreeItem nodeId="356/schedule" label="Schedule" />
                <TreeItem nodeId="356/assignments" label="Assignments" >
                    <TreeItem nodeId="356/assignments/lab0" label="Lab 0" />
                    <TreeItem nodeId="356/assignments/lab1" label="Lab 1" />
                    <TreeItem nodeId="356/assignments/lab2" label="Lab 2" />
                    <TreeItem nodeId="356/assignments/alpha-zero-lab" label="Alpha Zero Lab" />
                    <TreeItem nodeId="356/assignments/project1" label="Project 1" />
                    <TreeItem nodeId="356/assignments/project2" label="Project 2" />
                    <TreeItem nodeId="356/assignments/project3" label="Project 3" />
                    <TreeItem nodeId="356/assignments/project4" label="Project 4" />
                    <TreeItem nodeId="356/assignments/coded-bias-review" label="Coded Bias Review" />
                    <TreeItem nodeId="356/assignments/alphago-review" label="AlphaGo Review" />
                    <TreeItem nodeId="356/assignments/deepracer" label="DeepRacer" />
                </TreeItem>
            </TreeItem>
            <TreeItem nodeId="418" label="CS 418">
                <TreeItem nodeId="418/schedule" label="Schedule" />
                <TreeItem nodeId="418/syllabus" label="Syllabus" />
                <TreeItem nodeId="X418/homework" label="Homework">
                    <TreeItem nodeId="418/hw0" label="HW 0" />
                    <TreeItem nodeId="418/hw1a" label="HW 1A" />
                    <TreeItem nodeId="418/hw1b" label="HW 1B" />
                    <TreeItem nodeId="418/hw1c" label="HW 1C" />
                    <TreeItem nodeId="418/hw1d" label="HW 1D" />
                    <TreeItem nodeId="418/hw2a" label="HW 2A" />
                    <TreeItem nodeId="418/hw2b" label="HW 2B" />
                    <TreeItem nodeId="418/hw2c" label="HW 2C" />
                    <TreeItem nodeId="418/hw3a" label="HW 3A" />
                    <TreeItem nodeId="418/hw4a" label="HW 4A" />
                    <TreeItem nodeId="418/hw4b" label="HW 4B" />
                    <TreeItem nodeId="418/hw5a" label="HW 5A" />
                    <TreeItem nodeId="418/hw7a" label="HW 7A" />
                </TreeItem>
                <TreeItem nodeId="418/resources" label="External Resources" />
                <TreeItem nodeId="418/old-exams" label="Old Exams" />
            </TreeItem>
        </TreeView>
    );
}
