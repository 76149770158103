import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid'; // Grid version 1
import Description from "./description.mdx"
import house_dark from './house_dark.png'
import house_light from './house_light.png'


export default function Index() {
    return (
        <Grid container spacing={2} columns={{ xs: 6, sm: 6, md: 12 }}>
            <Grid item xs={12}>
                <h1>CS 257 - Computer Science II</h1>
            </Grid>
            <Grid item xs={6}>
                <Description />

                <Alert severity="info">
                    If this is your first time here, use the menu on the left
                    to find the Syllabus and the Schedule. The schedule is the
                    most important page to check regularly.
                </Alert>
            </Grid>
            <Grid item xs={6}>
                <img src={house_dark} className="only-dark-mode" alt="A person pointing at a house with a binary address." />
                <img src={house_light} className="only-light-mode" alt="A person pointing at a house with a binary address." />
            </Grid>
        </Grid>
    )
}
