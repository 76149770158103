import MuiLink from '@mui/material/Link';

export default function Reading() {
    return [
        'LCPP 0 vscode parts',               // 0
        <MuiLink href="/cs257/book/chapter1.pdf" target="_blank">LCPP 1</MuiLink>,
        <MuiLink href="/cs257/book/chapter2.pdf" target="_blank">LCPP 2</MuiLink>,
        <MuiLink href="/cs257/book/chapter3.pdf" target="_blank">LCPP 3</MuiLink>,
        <MuiLink href="/cs257/book/chapter4.pdf" target="_blank">LCPP 4</MuiLink>,
        <div>
            <MuiLink href="/cs257/book/chapter5.pdf" target="_blank">LCPP 5</MuiLink><br />
            <MuiLink href="/cs257/book/chapterO.pdf" target="_blank">LCPP O</MuiLink>
        </div>,
        'LCPP 6',
        <div>
            <MuiLink href="/cs257/book/chapter7.pdf" target="_blank">LCPP 7</MuiLink><br />
            <MuiLink href="/cs257/book/chapter23.pdf" target="_blank">LCPP 23</MuiLink>
        </div>,
        '',
        <MuiLink href="/cs257/book/chapter9.pdf" target="_blank">LCPP 9</MuiLink>,
        '',
        <MuiLink href="/cs257/book/chapter10.pdf" target="_blank">LCPP 10.1-10.9</MuiLink>,
        <MuiLink href="/cs257/book/chapter11.pdf" target="_blank">LCPP 11</MuiLink>,
        <MuiLink href="/cs257/book/chapter13.pdf" target="_blank">LCPP 13</MuiLink>,
        '',
        'LCPP 14',
        'LCPP 16 and 17 and 18',
        'LCPP 19',
        'LCPP 20',
    ]
}

