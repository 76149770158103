import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from '@mui/material/Paper';
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { format, addDays, isAfter, isSameDay } from 'date-fns'

interface ScheduleContent {
    start_date: Date;
    end_date: Date;
    days: string[];
    reading: string[];
    topics: ReactNode[];
    due: ReactNode[];
    link_dates: boolean;
}

interface ScheduleRow {
    id: Number;
    date: Date;
    reading: string;
    topic: ReactNode;
    due: ReactNode;
}

export default function Schedule(props: ScheduleContent) {
    let holidays: Date[] = [
        new Date(2022, 10, 11),         // Veteran's day
        new Date(2022, 10, 23),         // Thanksgiving
        new Date(2022, 10, 24),         // Thanksgiving
        new Date(2022, 10, 25),         // Thanksgiving
        new Date(2023, 0, 16),          // MLK Jr. Day
    ]

    let rows: ScheduleRow[] = [];           // dates we meet
    let row_num = 0;
    for (let next_date = props.start_date; !isAfter(next_date, props.end_date); next_date = addDays(next_date, 1)) {
        const isHoliday = (d: Date) => isSameDay(next_date, d);

        let reading = props.reading && props.reading.length > row_num ? props.reading[row_num] : "";
        let topic = props.topics && props.topics.length > row_num ? props.topics[row_num] : "";
        let due = props.due && props.due.length > row_num ? props.due[row_num] : "";

        if (!holidays.some(isHoliday) && props.days.includes(format(next_date, 'EEEE'))) {
            rows.push({
                id: row_num,
                date: next_date,
                reading: reading,
                topic: topic,
                due: due,
            });
            row_num += 1;
        }
    }

    let show_due = props.due === undefined || props.due.length === 0 ? "none" : "table-cell";

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Topics</TableCell>
                            <TableCell>Reading</TableCell>
                            <TableCell style={{ display: show_due }}>Due</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(function ({ id: i, date: dt, reading: r, topic: t, due: du }) {
                            let lesson_path = "lessons/" + i;
                            let lesson_link = <Link to={lesson_path}>{format(dt, 'EEE yyyy-MM-dd')}</Link>;
                            if (!props.link_dates) {
                                lesson_link = <span>{format(dt, 'EEE yyyy-MM-dd')}</span>;
                            }

                            return (
                                <TableRow key={format(dt, 'yyyy-MM-dd')}>
                                    <TableCell>{lesson_link}</TableCell>
                                    <TableCell>{t}</TableCell>
                                    <TableCell>{r}</TableCell>
                                    <TableCell style={{ display: show_due }}>{du}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
