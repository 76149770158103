import { Link as RouterLink } from "react-router-dom";
import Link from '@mui/material/Link';

export default function Topics() {
    return [
        <div>
            Overview, Tools<br />
            <Link component={RouterLink} to="/257/slides/linux/cli1">Linux command line 1</Link><br />
            git<br />
            <Link component={RouterLink} to="/257/lab0">Lab 0</Link>
        </div>,
        <div>
            <Link component={RouterLink} to="/257/slides/day2">C++ Basics</Link><br />
            <Link component={RouterLink} to="/257/lab1">Lab 1</Link>
        </div>,
        <div>
            <Link component={RouterLink} to="/257/lab2">Lab 2</Link>
        </div>,
        <div>
            <Link component={RouterLink} to="/257/slides/debugging">Debugging</Link>
        </div>,
        <div>
            <Link component={RouterLink} to="/257/slides/data-types">Fundamental Data Types</Link><br />
            <Link component={RouterLink} to="/257/lab3">Lab 3</Link>
        </div>,
        <div>
            <Link href="https://classroom.github.com/a/5HX9xTlj">Turtle Graphics In-class</Link><br />
        </div>,
        <div>
            Scope, Duration, and Linkage<br />
            <Link component={RouterLink} to="/257/topics/bitset">Practice with Bits</Link><br />
            <Link component={RouterLink} to="/257/lab4">Lab 4</Link>
        </div>,
        <div>
            <Link component={RouterLink} to="/257/slides/control-flow">Control Flow</Link><br />
            File I/O<br />
            <Link component={RouterLink} to="/257/projects/a">Project A</Link>
        </div>,
        <div>Finish Control Flow</div>,
        <div>
            <Link component={RouterLink} to="/257/slides/refs-and-pointers">References and Pointers</Link><br />
        </div>,
        <div>Finish pointers and references<br /><Link component={RouterLink} to="/257/lab5">Lab 5</Link></div>,
        <div>
            Enums, Structs<br />
            <Link component={RouterLink} to="/257/projects/b">Project B</Link>
        </div>,
        <div>Arrays, Strings, and Dynamic Allocation<br />
         Reading Quiz 3
        </div>,
        <div>Basic OOP<br /><Link href="https://github.com/cs257w23/rpc-chat-demo">RPC chat demo</Link></div>,
        <div>OOP catch-up</div>,
        <div>
            <Link component={RouterLink} to="/257/slides/oop2">Inheritance</Link><br />
            <Link component={RouterLink} to="/257/lab6">Lab 6</Link>
        </div>,
        <div>More object relationships, Inheritance, Virtual Functions</div>,
        <div>Intro to wxWidgets</div>,
        <div><Link component={RouterLink} to="/257/projects/c">Lab 7 and Project C</Link></div>,
    ];
}
