import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from '@mui/material/Link';

export default function Topics() : ReactNode[] {
    return [
        <div><Link component={RouterLink} to="/418/slides/0-prelims">Preliminaries</Link><br /><Link component={RouterLink} to="/418/hw0">HW 0</Link></div>,
        <div><Link component={RouterLink} to="/418/slides/1-dfas">DFAs</Link><br /><Link component={RouterLink} to="/418/hw1a">HW 1A</Link></div>,
        <div><Link component={RouterLink} to="/418/slides/2-nfas">NFAs</Link><br /><Link component={RouterLink} to="/418/hw1b">HW 1B</Link></div>,
        <div>
            <Link component={RouterLink} to="/418/slides/3-regex">Regular Expressions</Link><br />
            <Link component={RouterLink} to="/418/hw1c">HW 1C</Link>
        </div>,
        <div>
            <Link component={RouterLink} to="/418/slides/4-notreg/minimization">DFA Minimization</Link><br />
            <Link component={RouterLink} to="/418/slides/4-notreg/myhill-nerode">Myhill-Nerode</Link><br />
            <Link component={RouterLink} to="/418/slides/4-notreg/pumping-lemma">Pumping Lemma</Link><br />
            <Link component={RouterLink} to="/418/hw1d">HW 1D</Link>
        </div>,
        <div>
            <Link component={RouterLink} to="/418/slides/5-cfg">Context-free grammars</Link><br />
            <Link component={RouterLink} to="/418/hw2a">HW 2A</Link>
        </div>,
        <div>
            <Link component={RouterLink} to="/418/slides/6-pda">Pushdown Automata</Link><br />
            <Link component={RouterLink} to="/418/hw2b">HW 2B</Link><br />
        </div>,
        <div>
            Parsing<br />
            <Link component={RouterLink} to="/418/hw2c">HW 2C</Link><br />
        </div>,
        <div>
            Review<br />
            Quiz 1
        </div>,
        <div>
            <Link component={RouterLink} to="/418/slides/8-lambda">Lambda calculus</Link>
        </div>,
        'MIDTERM',
        <div>
            <Link component={RouterLink} to="/418/slides/9-turing-machines">Turing Machines</Link><br />
            <Link component={RouterLink} to="/418/hw3a">HW 3A</Link><br />
        </div>,
        <div>
            <Link component={RouterLink} to="/418/slides/10-diagonalization">Diagonalization and Turing computability</Link><br />
            <Link component={RouterLink} to="/418/hw4a">HW 4A</Link><br />
        </div>,
        <div>
            <Link component={RouterLink} to="/418/slides/11-halting-problem">Halting Problem and Busy Beaver</Link><br />
            <Link component={RouterLink} to="/418/hw4b">HW 4B</Link><br />
        </div>,
        <div>Snow day</div>,
        <div>
            <Link component={RouterLink} to="/418/slides/12-rices-theorem">TM reductions and Rice's Theorem</Link><br />
            <Link component={RouterLink} to="/418/slides/13-p-vs-np">Big-O and P vs. NP</Link><br />
            <Link component={RouterLink} to="/418/hw5a">HW 5A</Link><br />
        </div>,
        <div>
            <Link component={RouterLink} to="/418/slides/14-np-complete">NP-Complete problems</Link><br />
        </div>,
        <div>
            SAT/SMT solvers<br />
            Z3<br />
            <Link component={RouterLink} to="/418/hw7a">HW 7A</Link><br />
        </div>,
        <div>
            <s>Quantum Computing</s><br />
            Quiz 2
        </div>,
        <div>Review</div>
    ]
}
