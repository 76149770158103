import { ReactNode } from "react";
import { Link } from "react-router-dom";

export default function Topics() : ReactNode[] {
    return [
        <Link to="/314/topics/intro">Course Introduction</Link>,
        <Link to="/314/topics/boolean-algebra">Boolean Algebra</Link>,
        <div>
            Logic gates and Circuits<br />
            Programming the boards<br />
            <Link to="/314/assignments/lab1">Lab 1</Link> and <Link to="/314/assignments/hw1">Homework 1</Link><br />
        </div>,
        <a href="https://1drv.ms/p/s!Aut_k8c38Nn4hIZWqZUtuwJfLtHQrw?e=plw0vq">WHiDL Intro</a>,
        <div>
            Bit shifts<br />
            Barrel shifters<br />
            <Link to="/314/assignments/lab2">Lab 2</Link> and <Link to="/314/assignments/hw2">Homework 2</Link><br />
        </div>,
        <Link to="/314/topics/addition-subtraction">Addition and Subtraction</Link>,
        <div>
            <Link to="/314/assignments/lab3">Lab 3</Link> and <Link to="/314/assignments/hw3">Homework 3</Link>
        </div>,
        <a href="https://1drv.ms/p/s!Aut_k8c38Nn4hIZdRRLH6mDDbG26zw?e=mn9pUZ">Sequential Logic</a>,
        <div>Counter<br /><Link to="/314/assignments/lab4">Lab 4</Link><br /><b>Quiz 1</b></div>,
        <div>
            <Link to="/314/assignments/hw4">Homework 4</Link><br />
            <Link to="/314/topics/arm/intro">Into to ARM programming</Link>
        </div>,
        <b>Midterm</b>,
        'The ALU',
        <div>Building ARM CPU<br />Lab and Homework 5</div>,
        'Building ARM CPU',
        <div>
            Endianness<br />
            Conditional suffixes<br />
            Subroutines<br />
            <Link to="/314/lessons/arm-programming-2">Notes</Link>
        </div>,
        <div>
            Arrays<br />
            Load/Store multiple<br />
            The Call stack<br />
            <Link to="/314/lessons/arm-programming-3">Notes</Link>
        </div>,
        <div>Finish up toy ARM CPU<br /><Link to="/314/assignments/hw6/">Homework 6</Link></div>,
        <div>Lab 7 and <Link to="/314/assignments/hw7">Homework 7</Link></div>,
        <div><Link to="/314/assignments/buffer-overflow-lab">Buffer overflow lab</Link><br /><b><Link to="/314/quiz2-review">Quiz 2</Link></b></div>,
        <div>The 8080<br /><s>Take-home portion of final</s><br /><Link to="/cs314/quiz2key.pdf">Quiz 2 Key</Link><br /><a href="https://1drv.ms/p/s!Aut_k8c38Nn4hIZh9A0Tb-bz-rCtgQ?e=DemQef">Caching</a><br /><a href="https://www.youtube.com/watch?v=jLTJ8Qm-G-I">Caching Video</a></div>
    ]
}
