import React from 'react';

interface SlideProps {
    children: React.ReactNode,
    layout: Layouts,
}

export enum Layouts {
    Title,
    Section,
    TitleAndBody,
    TitleAndTwoColumns,
}

export default function Slide(props: SlideProps) {
    return (
        <React.Fragment>{props.children}</React.Fragment>
    );
}


