import React from 'react';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';

interface QuestionProps {
    question: String,
    answer: String,
}

export default function Slide(props: QuestionProps) {
    const [userAnswer, setUserAnswer] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserAnswer(event.target.value);
    };

    let resultColor: "success" | "error" | "primary" | "secondary" | "info" | "warning" | undefined = undefined;
    let resultText = 'Answer';
    if (userAnswer !== '') {
        if (userAnswer === props.answer) {
            resultColor = "success";
            resultText = "Correct!";
        } else {
            resultColor = "error";
            resultText = "Incorrect";
        }
    }

    return (
        <Box>
            {props.question}
            <br />
            <TextField
                id="filled-basic"
                variant="filled"
                onChange={handleChange}
                color={resultColor}
                label={resultText}
                sx={{ mt: 1 }}
            />
        </Box>
    );
}


