// import { Link } from "react-router-dom";

export default function Due() {
    return [
        "",
        "Lab 0",
        "Lab 1",
        "Reading Quiz 1",
        "Lab 2",
        "",
        "Lab 3",
        "Reading Quiz 2",
        "",
        "Project A Rough Draft",
        <div>
            Lab 4<br />
            Project A Code Review
        </div>,
        <div>
            Project A Final Draft<br />
        </div>,
        <div>
            Lab 5<br />
            Reading Quiz 3
        </div>,
        <div>
        </div>,
        <div>
        </div>,
        <div>
            Project B Rough Draft
        </div>,
        <div>
            Reading Quiz 4<br />
            Project B Code Review
        </div>,
        <div>Project B Final Draft<br />Lab 6</div>,
        <div>Lab 7</div>,
    ];
}
