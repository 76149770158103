import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Icons
import IconButton from "@mui/material/IconButton";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import GlobalStyles from '@mui/material/GlobalStyles';

import './index.css'
import Nav from './nav';

// Contexts that the outlet child has access to.
import { Outlet, useOutletContext } from "react-router-dom";
type OutletContext = {
  slideShowContext: SlideShowContext,
  extraButtonsContext: ExtraButtonsContext,
}
type SlideShowContext = [isSlideShow: boolean, setIsSlideShow: React.Dispatch<React.SetStateAction<boolean>>];
type ExtraButtonsContext = [extraButtons: React.ReactNode[], setExtraButtons: React.Dispatch<React.SetStateAction<React.ReactNode[]>>];

export default function App() {

  // Light / dark mode
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";
  const [mode, setMode] = React.useState<"light" | "dark">(prefersDarkMode);

  // Side menu
  const [drawerOpen, openDrawer] = React.useState(false);
  const toggleDrawer = () => {
    if (drawerOpen) {
      openDrawer(false);
    } else {
      openDrawer(true);
    }
  }

  // Icon buttons that the outlet component wants to add to the nav bar.
  const outletContext = {
    slideShowContext: React.useState(false),
    extraButtonsContext: React.useState<React.ReactNode[]>([]),
  };

  // For sharing state with the main content outlet.
  let contentWidth = outletContext.slideShowContext[0] ? '100%' : '900px';

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <React.Fragment>

      <GlobalStyles styles={{
        '.only-light-mode': { display: mode === "light" ? 'block' : 'none' },
        '.only-dark-mode': { display: mode === "dark" ? 'block' : 'none' },
        '.no-slide': { display: outletContext.slideShowContext[0] ? 'none' : 'block' },
        '.no-notes': { display: outletContext.slideShowContext[0] ? 'block' : 'none' },
        '.speaker-notes': { display: process.env.REACT_APP_SPEAKER === "1" ? 'block' : 'none' },
        '.invert-when-dark': { filter: mode === "light" ? 'none' : 'invert(100%)' },
        '.invert-when-light': { filter: mode === "dark" ? 'none' : 'invert(100%)' },
      }}
      />

      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex', height: '100vh' }}>
          <CssBaseline enableColorScheme />
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <Toolbar>
              <IconButton onClick={() => openDrawer(true)} color="inherit">
                <MenuOpenIcon sx={{ mr: 1, display: { xs: 'block', lg: 'none' } }} />
              </IconButton>
              <Typography variant="h6" sx={{ minWidth: 220 }}>
                DeFreez Classes
              </Typography>

              <Alert
                variant="outlined"
                severity="warning"
                sx={{
                  flexGrow: 1,
                }}
              >
                You are viewing an old version of this site.
              </Alert>

              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="term-select-label">Winter 2023</InputLabel>
                <Select
                  labelId="term-select-label"
                  id="term-select"
                  label="Previous terms"
                >
                  <MenuItem component={Link} href="https://teaching.defreez.com">Fall 2023</MenuItem>
                  <MenuItem component={Link} href="https://s23.teaching.defreez.com">Spring 2023</MenuItem>
                  <MenuItem>Winter 2023</MenuItem>
                </Select>
              </FormControl>

              {
                // Buttons added by the outlet child
                outletContext.extraButtonsContext[0]
              }

              <IconButton
                sx={{ ml: 1 }}
                onClick={colorMode.toggleColorMode}
                color="inherit"
              >
                {theme.palette.mode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>

            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" sx={{ width: 250, display: { xs: 'none', lg: 'block' } }} PaperProps={{ sx: { width: 250 } }}>
            <Toolbar />
            <Nav />
          </Drawer>
          <Drawer
            variant="temporary"
            sx={{ width: 250, display: { xs: 'block', lg: 'none' }, zIndex: 10000 }}
            open={drawerOpen}
            onClose={toggleDrawer}
            PaperProps={{ sx: { width: 250 } }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Typography variant="h6" sx={{ m: 2 }}>
              DeFreez Classes
            </Typography>
            <Nav />
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', mt: '64px', mb: '32px', ml: 4, mr: 4 }}>
            <Box className="section-to-print" sx={{ flexGrow: 1, maxWidth: contentWidth }}>
              <Outlet context={outletContext} />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </React.Fragment >
  );
}

// Function for the outlet child to gain access to the app context.
export function useParentContext(): OutletContext {
  return useOutletContext<OutletContext>();
}
