import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid'; // Grid version 1
import Description from "./description.mdx"
import lemmings_dark from './lemmings_dark.png'
import lemmings_light from './lemmings_light.png'


export default function Index() {
    return (
        <Grid container spacing={2} columns={{ xs: 6, sm: 6, md: 12 }}>
            <Grid item xs={12}>
                <h1>CS 418 - Theory of Computation</h1>
            </Grid>
            <Grid item xs={12}>
                <div style={{float: 'right', margin: '20px'}}>
                    <img src={lemmings_dark} className="only-dark-mode" alt="A person pointing at a house with a binary address." />
                    <img src={lemmings_light} className="only-light-mode" alt="A person pointing at a house with a binary address." />

                    Cover image from Introduction to Formal Language Theory (1978) <br />
                    by Prof. Michael Harrison (UCB) <br />

                    Image copied from my theory prof. <a href="https://www.cs.ucdavis.edu/~rogaway/">Phil Rogaway</a>
                </div>

                <Description />

                <Alert severity="info">
                    If this is your first time here, use the menu on the left
                    to find the Syllabus and the Schedule. The schedule is the
                    most important page to check regularly.
                </Alert>
            </Grid>
        </Grid>
    )
}
