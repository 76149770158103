import { Link } from "react-router-dom";

export default function Due() {
    return ([
            "",
            <Link to="/356/assignments/lab1">Lab 0</Link>,
            <Link to="/356/assignments/lab2">Lab 1</Link>,
            <Link to="/356/assignments/lab2">Lab 2</Link>,
            <s>Lab 3 (skipped)</s>,
            "Lab 4 (tennis balls)",
            <b>Project 1</b>,
            "Lab 5 (Conv nets + FGSM notebook)",
            "Lab 6 (OpenCV shapes)",
            'Lab 7 (Word Embeddings)',
            <b>Project 2</b>,
            "",
            "",
            <Link to="/356/assignments/coded-bias-review">Coded Bias Movie Review</Link>,
            "",
            <b>Project 3</b>,
            <div><Link to="/356/assignments/alpha-zero-lab">AlphaZero lab</Link></div>,
            <Link to="/356/assignments/alphago-review">AlphaGo Movie Review</Link>,
            "",
            <Link to="/356/assignments/project4">Project 4</Link>,
        ]);
}
