// import { Link } from "react-router-dom";

export default function Due() {
    return ([
        '',
        '',
        <div>HW 0</div>,
        <div>HW 1A</div>,
        <div>HW 1B</div>,
        <div>HW 1C</div>,
        <div>HW 1D</div>,
        <div>HW 2A</div>,
        <div>HW 2B</div>,
        <div></div>,
        <div>HW 2C</div>,
        '',
        <div></div>,
        <div>HW 3A</div>,
        <div>HW 4A</div>,
        <div>HW 4B</div>,
        '',
        <div>HW 5A</div>,
        <div></div>,
        <div>HW 7A</div>,
        ]);
}
