export default function Reading() {
    return [
        'Sipser Ch. 0',
        'Sipser 1.1',
        'Sipser 1.2',
        'Sipser 1.3',
        <div>
            Sipser 1.4<br />
            Myhill-Nerode notes<br />
        </div>,
        'Sipser 2.1',
        'Sipser 2.2',
        'Sipser theorem 7.16',
        '',
        <div>
            TBD
        </div>,
        '',
        'Sipser 3.1-3.3',
        'Sipser 4.1',
        'Sipser 4.2',
        '',
        <div>Sipser 5.1<br /><s>Sipser 5.2</s></div>,
        'Sipser 7.1-7.3',
        'Sipser 7.4-7.5',
        '',
        '',
    ];
}
