
export default function Topics() {
    return [
        <div>
            Moodle lesson: Introduction to web development<br />
            Moodle lesson: How this course works<br />
            Assignment: File Management<br />
            Quiz: Key aspects for success<br />
            Moodle lesson: teaching.defreez.com<br />
            Introductions forum post
        </div>,
        <div>
            Tutorial 1<br />
            Tutorial 1 Case #1<br />
            Quiz Tutorial 1<br />
            Milestone 1<br />
        </div>,
        <div>
            Milestone 1 Peer Review<br />
            Tutorial 2<br />
            Tutorial 2 Case #2<br />
            Quiz Tutorial 2<br />
        </div>,
        <div>
            Tutorial 3<br />
            Tutorial 3 Case<br />
            Quiz Tutorial 3<br />
            Milestone 2<br />
        </div>,
        <div>
            Milestone 2 Peer Review<br />
            Tutorial 4<br />
            Tutorial 4 Case<br />
            Quiz Tutorial 4<br />
            Milestone 3<br />
        </div>,
        <div>
            Milestone 3 Peer Review<br />
            Tutorial 5<br />
            Tutorial 5 Case<br />
            Quiz Tutorial 5<br />
        </div>,
        <div>
            Tutorial 6<br />
            Tutorial 6 Case<br />
            Quiz Tutorial 6<br />
            Milestone 4<br />
        </div>,
        <div>
            Milestone 4 Peer Review<br />
            Tutorial 7<br />
            Tutorial 7 Case<br />
            Quiz Tutorial 7<br />
        </div>,
        <div>
            Tutorial 8<br />
            Tutorial 8 Case<br />
            Milestone 5<br />
        </div>,
        <div>
            Milestone 5 Peer review<br />
            Work on your final project website.<br />
            T9: Javascript for extra credit
        </div>,
        <div>
            Course reflection survey<br />
            Work on your final project website.
        </div>
    ];
}
